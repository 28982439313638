

















































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import Common from '../../common/Common'

@Component({
  components: {
  },
})
export default class Guide extends Vue {

  mounted () {
    if (this.$route.hash) {
      setTimeout(() => {
        (<any>document.getElementById(this.$route.hash.substring(1, this.$route.hash.length))).scrollIntoView(false)
        window.scrollBy(0, window.innerHeight - 100)
      }, 500)
    }
  }
}
